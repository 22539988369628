


































import { Component, Vue, Prop } from 'vue-property-decorator';
import Util from '@/utils/Util';
@Component({
	name: 'Rating',
})
export default class Rating extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	readonly name!: string;

	@Prop({
		type: Number,
		required: true,
	})
	readonly percentage!: number;

	@Prop({
		type: Number,
		required: true,
	})
	readonly record!: number;
	@Prop({
		type: Boolean,
		required: true,
		default: true,
	})
	readonly loading!: boolean;

	public subTitleText = this.$t("MainMenu.titleBarChar") as string;
	public max_name_lenght = 30;
	public name_tag = '';
	mounted(): void{
		this.subTitleText = Util.replaceTextWith(
					this.subTitleText,
					'%s',
					this.record.toString()
				);
		this.name_tag = this.name;
		if (this.name_tag.length > this.max_name_lenght) {
			this.name_tag = this.name_tag.substring(0,this.max_name_lenght-3) + '...'
		}
	}
}
