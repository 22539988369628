






























































































	import { Component, Prop, Watch } from 'vue-property-decorator';
	import MainMixin from '@/mixing/MixinMain.vue';
	import { IFilterData } from '@/model/main/IFilterData';
	import { IEvCat } from '@/model/main/IEvCat';
	import domtoimage from "dom-to-image";
	import html2canvas from "html2canvas";
	import jsPDF from "jspdf"

	@Component({
		name: 'FilterBarReports',
	})
	export default class FilterBarReports extends MainMixin {
		@Prop({
			type: Boolean,
			required: true,
		})
		public isDisabled!: boolean;
		public isDateEnd = false;
		public isDateTo = false;
		public filterNow: IFilterData = {
			dateTo: '',
			dateEnd: '',
			categories: [],
			camping: [],
			customer: '',
		};
		//public selectedElement: Array<string> = [];
		public dateToText = this.$t("FilterBar.inputDateTo");
		public dateEndText = this.$t("FilterBar.inputDateEnd");
		public filterText = this.$t("FilterBar.filterButtonText");
		public downloadText = this.$t("FilterBar.downloadButtonText");
		public TextDownloadPDF = this.$t("FilterBar.textDownload");
		public isLoading!: boolean;

		mounted(): void {
			if (this.getFilter !== undefined) {
				this.filterNow = this.getFilter;
			}
		}

		@Watch('getFilter')
		public changedFilter(newVal: IFilterData) {
			this.filterNow = newVal;
		}

		public newFilter(): void {
			const evCat: Array<IEvCat> = [];

			const newFilter: IFilterData = {
				dateTo: this.filterNow.dateTo,
				dateEnd: this.filterNow.dateEnd,
				categories: evCat,
				camping: this.filterNow.camping,
				customer: this.filterNow.customer,
			};

			this.updateFilter(newFilter);
		}
		public get downloadFile(): string {
			const now = new Date();
			let nameLogFile = `log_${localStorage.getItem(
				'client'
			)}_${
				this.getFilter.camping
			}_${now.getFullYear()}${now.getMonth() + 1}.txt`;
			return `${process.env.VUE_APP_API}/rutaLogSftp/?token=${localStorage.getItem('TOKEN')}&&ruta=/home/Resemblyzer/dataset/log/${nameLogFile}`;
		}
		/*public downloadWithCSS() {
            domtoimage
            .toPng(document.querySelector('#dashboard_main')!)
            .then(function(dataUrl: any) {
                var img = new Image();

                img.src = dataUrl;
                img.onload = function () {
                    var doc :any = '';
					console.log("img:", img.width,  img.height);
                    if (img.width > img.height) {
                        doc = new jsPDF('l', 'mm', [img.width, img.height])
                    } else {
                        doc = new jsPDF('p', 'mm', [img.width, img.height])
                    }         
                    doc.addImage (img, 'jpeg', 20, 20, img.width, img.height) // La relación se puede ajustar según sea necesario
					
					//doc.addPage()
					/*doc.addPage();
					doc.addImage(img, 'jpeg', 10, 10, img.width, img.height);*/
                  /*  const date = new Date();
                    const filename =
                        "dashboard - " +
                        date.getFullYear() +
                        ("0" + (date.getMonth() + 1)).slice(-2) +
                        ("0" + date.getDate()).slice(-2) +
                        ("0" + date.getHours()).slice(-2) +
                        ("0" + date.getMinutes()).slice(-2) +
                        ("0" + date.getSeconds()).slice(-2) +
                        ".pdf";
                    doc.save(filename);
                };
            })
            .catch(function(error: any) {
                console.error("oops, something went wrong!", error);
            });
        }*/

	// 	public generarPDF(){
	// 	// Supongamos que tienes un array de IDs de tablas que deseas recorrer
	// 	const tablaIDs = ['#tablas']
	// 	// Función para generar imágenes de tablas y agregarlas a un PDF
	// 	async function generarPDF() {
	// 		const pdf = new jsPDF();
	// 		for (const tablas of tablaIDs) {
	// 			const tabla = document.querySelector('#tablas');
	// 			const canvas = await html2canvas(tabla);
	// 			if (pdf.internal.getNumberOfPages() > 0) {
	// 				pdf.addPage();
	// 			}
	// 			const imgData = canvas.toDataURL('image/png');
	// 			pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // Ajusta las coordenadas y dimensiones según sea necesario
	// 		}
	// 		pdf.save('tablas.pdf')
	// 	}
	// }

	/*public async downloadPDF() {
		let elements = html2canvas(document.querySelector('#dashboard_main .tablas')! as HTMLElement) 
		let pdf = jsPDF('p', 'pt','a4', 1);
		var options = {
			background: '#fff',
		};
		var pageHeight = pdf.internal.pageSize.height; // Tamaño de una pagina
		var pageHeightLeft = pageHeight; // La utilizaremos para ver cuanto espacio nos queda
		var position = 0;
	
		for(let i = 0; i < elements.length; i++) {
			await html2canvas(elements[i]).then(function(canvas: any) {
				// Comprobamos salto
				if (pageHeightLeft - canvas.height <= 0) {
					pdf.addPage();
					position = 0; // Pintaremos en el inicio de la nueva pagina
				}
				pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, canvas.width, canvas.height, options);
				position += canvas.height; // Marcamos el siguiente inicio
				pageHeightLeft -= canvas.height; // Marcamos cuanto espacio nos queda
			});
		}
		pdf.save("informe.pdf");
	}*/

		public downloadPDF() {
			html2canvas(document.querySelector('#tablas')! as HTMLElement) 
				.then(function(canvas:any) {
					var HTML_Width = canvas.width;
					var HTML_Height = canvas.height;
					var top_left_margin = 15;
					var PDF_Width = HTML_Width+(top_left_margin*2);
					var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
					var canvas_image_width = HTML_Width;
					var canvas_image_height = HTML_Height;
					var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
					canvas.getContext('2d');
					var imgData = canvas.toDataURL("image/jpeg", 1.0);
					var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
					pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
					//for (var i = 0; i <= totalPDFPages; i++) { 
					//	pdf.addPage([PDF_Width, PDF_Height]);
					//	pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4), canvas_image_width, canvas_image_height);
					//}

					const date = new Date();
					const filename =
                        "dashboard - " +
                        date.getFullYear() +
                        ("0" + (date.getMonth() + 1)).slice(-2) +
                        ("0" + date.getDate()).slice(-2) +
                        ("0" + date.getHours()).slice(-2) +
                        ("0" + date.getMinutes()).slice(-2) +
                        ("0" + date.getSeconds()).slice(-2) +
                        ".pdf";
                    pdf.save(filename);
				})
		}
		
	}
